import Vue from 'vue'
import Router from 'vue-router'
import auth from './auth'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    auth,
    {
      path: '/',
      name: 'home',
      redirect: { name: 'users' },
      component: () => import(/* webpackChunkName: "home" */ '@/views/Home'),
      children: [
        {
          path: 'users',
          name: 'users',
          component: () =>
            import(/* webpackChunkName: "users" */ '@/views/Home/Users')
        },
        {
          path: 'users/new',
          name: 'user.new',
          component: () =>
            import(/* webpackChunkName: "users" */ '@/views/Home/Users/AddUser')
        },
        {
          path: 'users/:id',
          name: 'user.details',
          component: () =>
            import(
              /* webpackChunkName: "user" */ '@/views/Home/Users/UserDetails'
            )
        },
        {
          path: 'challenges',
          name: 'challenges',
          component: () =>
            import(
              /* webpackChunkName: "challenges" */ '@/views/Home/Challenges'
            )
        },
        {
          path: 'challenges/new',
          name: 'challenge.new',
          component: () =>
            import(
              /* webpackChunkName: "challenges" */ '@/views/Home/Challenges/AddChallenge'
            )
        },
        {
          path: 'challenges/:id',
          name: 'challenge.details',
          component: () =>
            import(
              /* webpackChunkName: "challenges" */ '@/views/Home/Challenges/ChallengeDetails'
            ),
          props: true
        },
        {
          path: 'challenges/:id/child',
          name: 'challenge.child',
          component: () =>
            import(
              /* webpackChunkName: "challenges" */ '@/views/Home/Challenges/AddChallenge'
            ),
          props: true
        },
        {
          path: 'flagged-user-actions',
          name: 'flaggedUserActions',
          component: () =>
            import(
              /* webpackChunkName: "reports" */ '@/views/Home/FlaggedUserActions'
            )
        },
        {
          path: 'flagged-users',
          name: 'flaggedUsers',
          component: () =>
            import(
              /* webpackChunkName: "reports" */ '@/views/Home/FlaggedUsers'
            )
        },
        {
          path: 'settings',
          name: 'settings',
          component: () =>
            import(/* webpackChunkName: "settings" */ '@/views/Home/Settings'),
          children: [
            {
              path: 'app-version-control',
              name: 'settings.appVersionControl',
              component: () =>
                import(
                  /* webpackChunkName: "settings" */ '@/views/Home/Settings/AppVersionControl'
                )
            },
            {
              path: 'app-versions',
              name: 'settings.appVersions',
              component: () =>
                import(
                  /* webpackChunkName: "settings" */ '@/views/Home/Settings/AppVersions'
                )
            },
            {
              path: 'privacy-policy',
              name: 'settings.privacyPolicy',
              component: () =>
                import(
                  /* webpackChunkName: "settings" */ '@/views/Home/Settings/PrivacyPolicy'
                )
            },
            {
              path: 'terms-and-condition',
              name: 'settings.termsAndCondition',
              component: () =>
                import(
                  /* webpackChunkName: "settings" */ '@/views/Home/Settings/TermsAndCondition'
                )
            }
          ]
        },
        {
          path: 'missions',
          name: 'missions',
          component: () =>
            import(/* webpackChunkName: "missions" */ '@/views/Home/Missions')
        },
        {
          path: 'missions/new',
          name: 'mission.new',
          component: () =>
            import(
              /* webpackChunkName: "missions" */ '@/views/Home/Missions/AddMission'
            )
        },
        {
          path: 'missions/:id',
          name: 'mission.details',
          component: () =>
            import(
              /* webpackChunkName: "missions" */ '@/views/Home/Missions/MissionDetails'
            )
        },
        {
          path: 'contents',
          name: 'contents',
          component: () =>
            import(/* webpackChunkName: "contents" */ '@/views/Home/Contents')
        },
        {
          path: 'organisations',
          name: 'organisations',
          component: () =>
            import(
              /* webpackChunkName: "organisations" */ '@/views/Home/Organisations'
            )
        },
        {
          path: 'organisations/:id',
          name: 'organisation.details',
          component: () =>
            import(
              /* webpackChunkName: "organisations" */ '@/views/Home/Organisations/OrganisationDetails'
            )
        },
        {
          path: 'organisations/new',
          name: 'organisation.new',
          component: () =>
            import(
              /* webpackChunkName: "organisations" */ '@/views/Home/Organisations/AddOrganisation'
            )
        },
        {
          path: 'charities',
          name: 'charities',
          component: () =>
            import(/* webpackChunkName: "charities" */ '@/views/Home/Charities')
        },
        {
          path: 'user-management',
          name: 'userManagement',
          component: () =>
            import(
              /* webpackChunkName: "userManagement" */ '@/views/Home/UserManagement'
            )
        }
      ]
    }
  ]
})
